* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

#pic,
#imAge,
#imAge-sign,
#video,
#music,
#imAgeTumb,
#picos,
#banner-logo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
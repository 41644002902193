.css-12rl710-MuiPaper-root-MuiDialog-paper {
    width: 100% !important;
    border-radius: 15px !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 27% !important;
    border-radius: 15px !important;
}

.css-uhb5lp {
    width: 100% !important;
    border-radius: 15px !important;
}

.css-1qmc5dd {
    width: 100% !important;
    border-radius: 15px !important;
}